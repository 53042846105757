/* eslint-disable react/no-danger */
import { useEffect, useRef } from 'react';

import classNames from 'classnames';
import root from 'react-shadow';

import { fixInvolveMe, fixIframeEmbeds, fixJumpLinks } from './fixes';

import type { WordPressArticleProps } from 'common-types/types/WordPressArticleProps';

import STYLES from './WordPressArticle.module.scss';

const isShadowDomReady = () => {
  const shadowHost = document.querySelector('#wordpress-shadow-host');

  return !!(
    shadowHost?.shadowRoot && shadowHost.shadowRoot.children.length > 0
  );
};

const templateToClassesMap = {
  single: {
    content: 'wrap content',
    body: 'post-template-default single single-post single-format-standard wp-embed-responsive',
  },
  'full-width': {
    content: 'wrap content full-width',
    body: 'page-template page-template-page-templates page-template-full-width page-template-page-templatesfull-width-php page page-child wp-embed-responsive',
  },
  'narrow-width': {
    content: 'wrap content narrow-width',
    body: 'page-template page-template-page-templates page-template-narrow-width page-template-page-templatesnarrow-width-php page page-child wp-embed-responsive',
  },
  'travel-trends': {
    content: 'content travel-trends',
    body: 'page-template page-template-page-templates page-template-travel-trends page-template-page-templatestravel-trends-php page wp-embed-responsive tt-aos',
  },
};

const WordPressArticle = ({
  bodyStyles,
  htmlContent,
  ssr = true,
  template,
}: WordPressArticleProps) => {
  const shadowHostRef = useRef<HTMLDivElement>(null);
  const hasInitialised = useRef(false);

  const isTravelTrends = template === 'travel-trends';

  const articleClassNames = classNames('entry-content', {
    'is-layout-constrained': isTravelTrends,
  });

  const bodyClassNames = templateToClassesMap[template]
    ? templateToClassesMap[template].body
    : templateToClassesMap['full-width'].body;

  const contentClassNames = templateToClassesMap[template]
    ? templateToClassesMap[template].content
    : templateToClassesMap['full-width'].content;

  useEffect(() => {
    if (shadowHostRef.current) {
      const shadowHost = shadowHostRef.current as HTMLDivElement;

      if (shadowHost.shadowRoot) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (!hasInitialised.current) {
              initFixes();
            }
          });
        });

        const initFixes = () => {
          hasInitialised.current = true;

          // Initialise fixes
          fixInvolveMe(shadowHost);
          fixIframeEmbeds(shadowHost);
          fixJumpLinks(shadowHost);

          observer.disconnect();
        };

        const config = { childList: true, subtree: true };
        observer.observe(shadowHost.shadowRoot, config);

        // If ShadowDOM is already ready, initialise immediately
        if (isShadowDomReady() && !hasInitialised.current) {
          initFixes();
        }
      }
    }
  }, []);

  return (
    <root.div
      id="wordpress-shadow-host"
      ssr={ssr}
      ref={shadowHostRef}
      className={classNames(
        STYLES.WordPressShadowHost,
        isTravelTrends && STYLES['WordPressShadowHost--travel-trends'],
      )}
    >
      <link
        rel="stylesheet"
        href="https://js.skyscnr.com/sttc/wordpress-vip/assets/themes/skyscanner-4.0/theme.9c5cf9b61748dc64bea0.css"
      />
      <link
        rel="stylesheet"
        href="https://js.skyscnr.com/sttc/wordpress-vip/assets/core/blocks/gallery/style.b62f1aad9724bb0b55ce.css"
      />
      <link
        rel="stylesheet"
        href="https://js.skyscnr.com/sttc/wordpress-vip/assets/core/blocks/cover/style.65a5ece4f082c77d447f.css"
      />
      <link
        rel="stylesheet"
        href="https://www.skyscanner.net/sttc/inspiration-image-builder/inspiration-image-builder-bundle-bee9778cf9e98816a9e1.min.css"
      />
      <div id="body" className={bodyClassNames}>
        <div className={contentClassNames}>
          <article className="page type-page status-publish has-post-thumbnail hentry">
            <div
              className={articleClassNames}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
          </article>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: bodyStyles,
        }}
      />
    </root.div>
  );
};

export default WordPressArticle;
