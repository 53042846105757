/**
 * The following fix uses a slot to move the embed element outside of the Shadow DOM so it can be targeted globally.
 * More about slots: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/slot
 * @param {HTMLDivElement} shadowHost - The WordPress Shadow Host DIV
 * @returns {void}
 */
const fixInvolveMe = (shadowHost: HTMLDivElement) => {
  const embedName = 'involveme_embed';
  const embedElem = shadowHost.shadowRoot?.querySelector(`.${embedName}`);

  if (embedElem) {
    /*
     *
     *
     */

    // Create the slot host which will contain the embed element
    const slotHost = document.createElement('span');
    slotHost.slot = 'involveme_embed';

    //  Create the slot content by cloning from original embed element
    const clonedElem = embedElem.cloneNode(true) as HTMLDivElement;
    slotHost.appendChild(clonedElem);

    // Recreate any scripts so they download and execute
    const scripts = clonedElem.querySelectorAll('script');

    scripts.forEach((script) => {
      // Create a new script element
      const newScript = document.createElement('script');

      // Copy attributes from the original script to the new script
      for (const attr of script.attributes) {
        newScript.setAttribute(attr.name, attr.value);
      }

      // Replace the original script with the new script
      script.parentNode?.replaceChild(newScript, script);
    });

    // Add slot host to the web component (outside the Shadow DOM)
    shadowHost.appendChild(slotHost);

    // Add a slot to the Shadow DOM
    const slot = document.createElement('slot');
    slot.name = 'involveme_embed';
    embedElem.replaceWith(slot);
  }
};

/**
 * The following fix initialises the iFrameResize utility on embedded widgets.
 * @param {HTMLDivElement} shadowHost - The WordPress Shadow Host DIV
 * @returns {void}
 */
const fixIframeEmbeds = (shadowHost: HTMLDivElement) => {
  const calcIframe = shadowHost.shadowRoot?.querySelector('#skyscanner-calc');
  const everywhereIframe = shadowHost.shadowRoot?.querySelector(
    '#skyscanner-everywhere-agency',
  );

  if (calcIframe) {
    // @ts-ignore
    window.iFrameResize({ log: false }, calcIframe);
  }

  if (everywhereIframe) {
    // @ts-ignore
    window.iFrameResize({ log: false }, everywhereIframe);
  }
};

/**
 * The following fixes anchor/jump links
 * @param {HTMLDivElement} shadowHost - The WordPress Shadow Host DIV
 * @returns {void}
 */
const fixJumpLinks = (shadowHost: HTMLDivElement) => {
  shadowHost.shadowRoot?.addEventListener('click', (e) => {
    const target = e.target as HTMLAnchorElement;

    if (target.matches('a[href^="#"]')) {
      e.preventDefault();

      shadowHost.shadowRoot
        ?.querySelector(target.getAttribute('href')!)
        ?.scrollIntoView();
    }
  });
};

export { fixInvolveMe, fixIframeEmbeds, fixJumpLinks };
